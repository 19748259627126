<template>

<div>


  <exam-alert v-model:exam="selectedExam"></exam-alert>

  <container :loading="loading" class="mt-8" min-height="50">
    <template #heading>
      <div  class="border-b pb-2 mt-6 text-left text-3xl">My Enrolled Courses</div>
    </template>

    <div v-if="enrolledCourses.length === 0" class="w-full py-10 text-center font-semibold text-gray-300 text-2xl">
      You didn't enrolled any course
    </div>

    <div v-else class="grid md:grid-cols-3 gap-4 py-4 w-full" v-fade-in="!loading" >
      <student-course-item v-for="participant in enrolledCourses" :participant="participant" :key="participant.id"></student-course-item>
    </div>
  </container>

  <container :loading="examLoading" class="mt-10" min-height="50">
    <template #heading>
      <div class="border-b py-1 mt-6 text-left text-3xl ">My Exams</div>
    </template>
    <div v-if="examList.length===0" class="w-full py-10 text-center font-semibold text-gray-300 text-2xl">
      You dont's have any exam
    </div>
    <div v-else class="grid md:grid-cols-3 gap-4 py-4 w-full" v-fade-in="!examLoading" >
      <exam-item v-for="exam in examList" :exam="exam" @click-enter="enter_exam" :key="exam.id"></exam-item>
    </div>

  </container>

  <container :loading="loading" class="mt-10" v-if="unpaidCourses.length > 0">
    <template #heading>
      <div class="border-b pb-2 mt-6 text-left text-3xl">Unpaid Courses</div>
    </template>
    <div class="grid md:grid-cols-3 gap-4 py-4 w-full" v-fade-in="!loading" >
      <student-course-item v-for="participant in unpaidCourses" :participant="participant" :key="participant.id"></student-course-item>
    </div>
  </container>
</div>
<!--  <div>-->
<!--    {{ $store.state.appData }}-->
<!--  </div>-->
<!--  -->



</template>

<script>
import Overly from "../../../components/overly";
import Container from "../../layouts/container";
import StudentCourseItem from "../courses/components/student-course-item";
import ExamItem from "../exams/components/exam-item";
import ExamAlert from "../exams/components/exam-alert";
import examAlertMixins from "../exams/mixins/exam-alert-mixins";
export default {
  name: "Dashboard",
  components: {ExamAlert, ExamItem, StudentCourseItem, Container, Overly},
  mixins:[examAlertMixins],
  data() {
    return {
      items:[],
      detailsLoading: true,
      loading: true,
      examLoading: true,
      studentCourses: [],
      exams: [],
    }
  },
  computed:{
    examList(){
      return Array.isArray( this.exams ) ? this.exams: [];
    },
    enrolledCourses(){

      return Array.isArray(this.studentCourses)
          ? this.studentCourses.filter( item => item.due == 0 && item.active )
          :[];
    },
    unpaidCourses(){
      return Array.isArray(this.studentCourses)
          ? this.studentCourses.filter( item => item.due > 0 || !item.active )
          :[];
    },
  },
  methods:{
    batchDataLoaded(){
      this.detailsLoading = false;
      scrollTo( '#course-details', 800 );
    },
    loadStudenCourses() {
      this.$store.dispatch( 'batch/getStudentCourses', {
        params: {
          branch_courses_only: 'true',
          with: 'batch-and-course,service-point-and-branch'
        },
        success: ({data}) => this.studentCourses = data.courses,
        error:(response) => { }
      }).finally( () => this.loading = false )
    },
    loadExams( ){
      const params = {};
      if( this.programId ) {
        params.program_id = this.programId;
      }

      this.$store.dispatch( 'exam/list', {
        params,
        success: ({data}) => {

          this.exams = data.exams;

        },
        error:(response) => { }

      }).finally( () => this.examLoading = false )
    }

  },
  created( ) {


    setTimeout( this.loadStudenCourses, 1 );
    setTimeout( this.loadExams, 1 );

    //console.log( 'this.$store.flashMessage', this.$store.state.flashMessage );
  }

};
</script>

<style scoped>
</style>
